@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: 'montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}
ul li{
  list-style: none;
}
ul{
  margin: 0px;
  padding: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#components-layout-demo-top-side .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

.site-layout-background {
  background: #fff;
}

.primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
/*=============================
            LOADER
===============================*/
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 10% auto;
  color: transparent;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loginformPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-indent: -99999em;
}

.loginform {
  max-width: 600px;
  /* margin: auto; */
  padding: 20px;
  box-shadow: 0px 10px 30px #0000001c;
  width: 40%;
}
.staticFAQ {
  max-width: 991px;
  margin: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: blue;
}
.loginform .ant-row {
  flex-flow: inherit !important;
}
.loginform .ant-col.ant-form-item-label {
  flex: 0 0 25% !important;
  width: 25% !important;
  margin-right: 10px !important;
}
.loginform .ant-col.ant-form-item-control {
  width: 100% !important;
}

.logo-area {
  text-align: center;
  margin-bottom: 10px;
}

.dashboard-text {
  color: #ffffff;
  font-size: 15px;
  padding-left: 16px;
}

.ant-table-pagination {
  text-align: center;
  float: none !important;
}

.right-top-corner {
  float: right;
}
.right-top-corner span {
  color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
}

.my-label {
  font-weight: 600;
}
.right-section {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 70px;
  padding-bottom: 5px;
  min-height: 80vh;
}
.ant-layout-sider {
  margin-top: 70px;
}
.sidebar-icon {
  font-size: 22px !important;
}

.ant-layout-header {
  padding: 0px;
  position: fixed;
  z-index: 99999;
  width: 100%;
}

.ant-layout-footer {
  padding: 10px 50px;
}
.ant-form-item-label > label.ant-form-item-required::before {
  color: #188ffe;
}
.ant-drawer {
  z-index: 9999999999;
}

.filterLabel,
.paymentLabel,
.additionalInfoLabel {
  font-weight: 600;
}
.filterValue, .paymentValue, .additionalInfoValue {
  margin-left: 6px;
}

/*header css start here*/
.static-header {
  padding: 15px 0;
  box-shadow: 0 0.5rem 1rem rgba(173, 172, 172, 0.15);
  position: fixed;
  width: 100%;
  background: #ffffff;
  z-index: 1000;
}
.static-container {
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;
}
.static-header .static-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.static-header .static-navbar .logo img{
  max-width: 250px;
}
.static-header .static-navbar .header-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: none;
}
.static-header .static-navbar .header-menu li a {
  font-weight: 600;
  transition: .3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  color: rgb(32, 32, 32) !important;
  font-size: 16px !important;
  font-family: 'montserrat' !important;
}
.static-header .static-navbar .header-menu li a:hover{
  color: rgb(0, 0, 255)!important;
}
.static-header .static-navbar .header-menu li {
  height: auto;
  margin: 0px;
}
.btn-round {
  padding: 15px 35px;
  border-radius: 25px;
  font-size: 16px !important;
  transition: all 0.1s ease-in !important;
  font-weight: bolder;
  background-color: #0000ff;
  color: white;
  box-shadow: 5px 5px 5px 0px rgba(30, 0, 89, 0.2);
  border: solid 2px #0000ff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  height: auto;
}
.btn-round:hover {
  background-color: #ffffff;
  color: #000;
}


#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #00f;
  position: relative;
  height: 3px;
  width: 30px;
  transition: transform .3s cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 13px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top:  0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: -2px;
  transform: rotate(-405deg);
}
.menu-button-container {
  display: none;
}

/*header css ends here*/

/*footer start here*/
.static-footer {
  position: relative;
  padding: 100px 0;
  background: #0000ff;
}
.static-footer .static-footer-element h5 {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.static-footer .static-footer-element li, .static-footer .static-footer-element li a {
  display: block;
  font-size: 16px;
  color: #ffffff;
}
.static-footer-socialicon {
  display: flex;
}
.static-footer-socialicon li a {
  width: 50px;
  height: 50px;
  display: flex!important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  margin-right: 10px;
  color: blue!important;
  font-size: 20px!important;
  transition: .3s ease-in-out;
}
.static-footer-socialicon li a:hover {
  border: 1px solid white!important;
  background: transparent;
  color: #ffffff!important;
}
/*footer ends here*/
/*homepage css start here*/
/* .staticbanner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 189, 0.55);
  top: 0;
} */
.staticbanner .static-container{
  position: relative;
  z-index: 10;
}
.staticbanner h1 {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 20px;
}
.staticbanner p {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}
.staticbanner p .d-block{
  display: block;
}
.staticbanner ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.staticbanner ul li a img {
  width: 240px;
  height: 65px;
  transition: .3s ease;
}
.staticbanner ul li {
  margin-right: 30px;
}
.staticbanner ul li a img:hover {
  transform: translate(0px, -1px);
  box-shadow: 0px 4px 20px 4px #cccccc;
}
.create-survey {
  position: relative;
  padding: 100px 0;
}
.create-survey .survey-left h2 {
  font-size: 56px;
  margin-bottom: 20px;
  line-height: 60px;
}
.create-survey .survey-left h2 .d-block{
  display: block;
}
.create-survey .survey-left p {
  font-size: 20px;
  margin-bottom: 0;
}
.create-survey .survey-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-survey .survey-right img {
  max-width: 60%;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.28);
}
.how-work {
  padding: 30px 0 80px;
  position: relative;
}
.how-work h2 {
  font-size: 46px;
  text-align: center;
  margin-bottom: 50px;
}
.work-block {
  max-width: 1140px;
  margin: auto;
}
.work-block .work-element {
  padding: 20px;
  transition: .3s ease;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 1px 15px #00000026;
}
.work-block .work-element:hover{
  transform: translate(0px, -10px);
}
.work-block .work-element h4 {
  margin-top: 10px;
  font-size: 26px;
  margin-bottom: 10px;
}
.work-block .work-element p {
  margin: 0;
  font-size: 18px;
}
.user-block img {
  width: 100px;
  height: 100px;
}
.Earn-hard-block {
  max-width: 1240px;
  margin: auto;
}
.Earn-hard-block .survey-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Earn-hard-block .survey-right img {
  max-width: 100%;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.28);
}
.Earn-hard-block .survey-left img {
  max-width: 25%;
  margin-top: 30px;
}
/*homepage css ends here*/
/*about us css start here*/
.static-about {
  position: relative;
  padding: 100px 0;
}
.static-about h2 {
  font-size: 56px;
  margin-bottom: 10px;
}
.static-about h5 {
  font-size: 24px;
}
.static-about p {
  font-size: 20px;
}
/*aboutus css ends here*/
/*contactus css start here*/
.staticContact-block {
  position: relative;
  padding: 150px 0 100px;
}
.staticContact-block .staticContact-left .text-gray{
  color: #9b9b9b;
  font-size: 30px;
}
.staticContact-block .staticContact-left  h3 {
  font-size: 30px;
  color: #1E005A;
}
.staticContact-block .staticContact-left  h3 span{
  color: #0000ff;
}
.staticContact-block .staticContact-left p {
  font-size: 20px;
  color: #212529;
}
.staticContact-block .staticContact-left {
  padding-right: 50px;
}
.staticContact-block .staticContact-left .static-contact-detail {
  display: flex;
}
.staticContact-block .staticContact-left .static-contact-detail li{
  margin-right: 30px;
}
.staticContact-block .staticContact-left .static-contact-detail li h4 {
  font-size: 30px;
}
.staticContact-block .staticContact-left .static-contact-detail li p span{
  display: block;
}
.staticContact-block .staticcontact-form {
  position: relative;
  padding: 20px;
  max-width: 80%;
  box-shadow: 10px 10px 10px 0px rgba(30, 0, 89, 0.2);
}
.staticContact-block .staticcontact-form .form-group {
  margin-bottom: 15px;
}
.staticContact-block .staticcontact-form .form-group label {
  display: block;
  color: #212529;
  font-size: 20px;
  margin-bottom: 10px;
}
.staticContact-block .staticcontact-form .form-group .static-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #212529!important;
  color: #212529;
  font-size: 16px;
}
/*contactus ends here*/
/*termscondition and policy page css start here*/
.staticTermsCondition {
  position: relative;
  padding: 120px 0 100px;
}
.staticTermsCondition .staticTermsCondition-block h1 {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 20px;
}
.staticTermsCondition .staticTermsCondition-block p {
  font-size: 20px;
  margin-bottom: 10px;
}
.staticTermsCondition .staticTermsCondition-block h4 {
  font-size: 26px;
  margin-bottom: 15px;
}
.statictext-blue {
  color: blue;
}
.statictext-red {
  color: red;
}
/*termscondition and policy page css ends here*/

@media (max-width: 1199px){
  .Earn-hard-block {
    max-width: 100%;
    margin: auto;
    padding-right: 80px;
    padding-left: 80px;
}
}

@media (max-width: 991px) {
  .menu-button-container {
    display: flex!important;
    background: transparent;
    width: 30px;
    height: 25px;
    align-items: center;
    cursor: pointer;
}
.staticContact-block .staticcontact-form{
  max-width: 100%;
}
  .static-container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}
  .menu {
    position: absolute;
    top: 0;
    margin-top: 70px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 4.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
  .static-header .static-navbar .header-menu li a{
    color: #ffffff!important;
  }

  .staticbanner{
    padding: 160px 0;
  }
  .create-survey {
    position: relative;
    padding: 30px 0;
  }
  .create-survey .survey-left h2 {
    font-size: 42px;
    margin-bottom: 10px;
    line-height: 50px;
  }
  .create-survey .survey-left p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .work-block .work-element{
    margin-bottom: 30px;
  }
  .how-work {
    padding: 30px 0 0px;
    position: relative;
}
.Earn-hard-block .survey-right img {
  max-width: 50%;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.28);
  margin: auto;
}
.Earn-hard-block {
  max-width: 100%;
  margin: auto;
  padding-right: 30px;
  padding-left: 30px;
}
}

@media (max-width: 768px){
  .staticbanner {
    padding: 120px 0 60px;
  }
  .staticTermsCondition .staticTermsCondition-block h1 {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 10px;
}
.staticTermsCondition .staticTermsCondition-block p .d-block {
  display: block;
}
.text-xd {
  font-size: 13px;
  margin-bottom: 10px;
}
.staticTermsCondition .staticTermsCondition-block p {
  font-size: 16px;
  margin-bottom: 10px;
}
  .staticContact-block {
    position: relative;
    padding: 100px 0px 50px;
  }
  .staticContact-block .staticContact-left .text-gray {
    color: #9b9b9b;
    font-size: 24px;
    margin-bottom: 0;
  }
  .staticContact-block .staticContact-left h3 {
    font-size: 24px;
    color: #1E005A;
    margin-bottom: 0;
  }
  .staticContact-block .staticContact-left p {
    font-size: 16px;
    color: #212529;
  }
  .staticContact-block .staticContact-left .static-contact-detail {
    display: block;
  }
  .staticContact-block .staticContact-left .static-contact-detail li h4 {
    font-size: 24px;
    line-height: 24px;
  }
  .staticContact-block .staticContact-left {
    padding-right: 0;
  }
  .staticbanner h1 {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .staticbanner p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .staticbanner ul li a img {
    width: 150px;
    height: 45px;
    transition: .3s ease;
  }
  .create-survey {
    position: relative;
    padding: 50px 0;
  }
  .create-survey .survey-left h2 {
    font-size: 42px;
    margin-bottom: 10px;
    line-height: 50px;
  }
  .create-survey .survey-left p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .create-survey .survey-right img {
    max-width: 80%;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.28);
  } 
  .how-work h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
  .work-block .work-element img {
    max-width: 30%;
  }
  .work-block .work-element h4 {
    margin-top: 0px;
    font-size: 22px;
    margin-bottom: 0px;
  }
  .work-block .work-element p {
    margin: 0;
    font-size: 14px;
  }
  .user-block img {
    width: 80px;
    height: 80px;
    max-width: 100%!important;
}
.how-work {
  padding: 30px 0 0px;
  position: relative;
}
.Earn-hard-block .survey-right img{
  margin-bottom: 20px;
}
.Earn-hard-block {
  max-width: 100%;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.static-footer {
  position: relative;
  padding: 30px 0;
  background: #0000ff;
}
.static-footer .static-footer-element h5 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.static-footer-element {
  margin-bottom: 10px;
}
.static-about {
  position: relative;
  padding: 80px 0 30px;
}
.static-about h2 {
  font-size: 36px;
  margin-bottom: 0px;
}
.static-about h5 {
  font-size: 20px;
}
}

@media (max-width: 574px){
  .static-header .static-navbar .logo img {
    max-width: 180px;
  }
  .staticTermsCondition-block {
    overflow: hidden;
}
  .staticTermsCondition {
    position: relative;
    padding: 90px 0 50px;
}
  .static-about h2 {
    font-size: 32px;
    margin-bottom: 0px;
  }
  .static-about p {
    font-size: 16px;
  }
  .static-about h5 {
    font-size: 20px;
  }
  .static-footer-socialicon li a {
    width: 40px;
    height: 40px;
    font-size: 16px!important;
  }
  .staticbanner {
    background-position: 80% 40%;
  }
  .menu{
    margin-top: 50px;
  }
  .staticbanner h1 {
    font-size: 26px;
    color: #ffffff;
    margin-bottom: 0px;
  }
  .staticbanner p {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .staticbanner {
    padding: 100px 0 60px;
  }
  .create-survey{
    padding: 30px 0;
  }
  .create-survey .survey-left h2 {
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 34px;
  }
  .create-survey .survey-left p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .create-survey .survey-right img{
    max-width: 100%;
  }
}

@media (max-width: 420px){
  .staticbanner h1{
    font-size: 20px;
  }
  .static-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .staticbanner ul{
    display: block;
  }
  .staticbanner ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.ant-layout-footer {
  position: relative;
}
.git-info {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 5px;
  font-size: 10px;
}