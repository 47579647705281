.race-list .ant-table-content {
  overflow-x: auto;
}

.race-list .ant-table-cell .allowed-user-wallet-ids {
  width: 304px;
  overflow-x: scroll;
}

.race-list .ant-table-cell .allowed-chicken-ids {
  width: 120px;
  overflow-x: scroll;
}
