.createBetaUserLayout__heading{
    border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* border: 1px solid; */
        padding: 10px;
        font-size: 1rem;
        /* background: #daebe8; */
        margin: 20px 50px;
        font-family: 'Noto Sans', sans-serif;
        width: 80%;
        margin: 0 auto;
}

.createBetaUserLayout__heading__toggle{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.createBetaUserLayout__form{
    font-family: 'Noto Sans', sans-serif;
    width: 80%;
    margin: 0 auto;
    border: 1px solid #daebe8;
    margin-top: 20px;
    border-radius: 5px;
    background: #ffff;
}