.addSettings{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: system-ui;
    flex-direction: column;
}


.addSettings__input{
    width: 80%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border: 1px solid;
        width: 42%;
        padding: 30px 20px;
        border-radius: 4px;
}

.listSettings{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 10px 0;
    
}

.listSettings >h1{
    font-size: 1.5rem;
}
.addSettings >h1{
    font-size: 2rem;
}

.listSettings__resume_race_counter{
    width: 95%;
    height: 50px;
    margin-top: -47px;
}